<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" sm="4">
        <BaseCard :heading="`<span class='font-weight-bold saldo px-2 py-1'>(Disponible $${retSaldo})</span>`">
            <div class="mt-0">
                <v-alert
                text
                dense
                color="info"
                icon="mdi-information"
                border="left"
                class="subtitle-2"
                v-if="nuevo"
                ><b>!Atención!</b> Monto minimo de retiros es de {{monto_inicial}} $.</v-alert>
                <v-alert
                    text
                    dense
                    type="error"
                    border="left"
                    v-if="viewError"
                    class="subtitle-2"
                ><b>!Atención!</b> {{msgError}}.</v-alert>
                <v-card-text>
                    <v-text-field
                        ref="vaMonto"
                        outlined
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="va_monto"
                        :placeholder="`Mínimo ${monto_inicial}`"
                        :error="viewError"
                        @keyup="viewError=false"
                        v-if="nuevo"
                    ></v-text-field>
                </v-card-text>
                <v-alert
                    text
                    dense
                    color="info"
                    icon="mdi-information"
                    border="left"
                    class="subtitle-2"
                    > Para retirar fondos, debe tener agregadas las direcciones de sus billeteras en su perfil.</v-alert>
            </div>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-warning w-100 subtitle-1 font-weight-medium"
            @click="genRetiro"
            v-if="nuevo"
            :disabled="loadingB"
            data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
            >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingB"></span>
                 Solicitar Retiro  
            </button>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-warning w-100 subtitle-1 font-weight-medium"
            @click="nuevoRetiro" 
            v-if="!nuevo"
            >
             Nuevo Retiro
            </button>
        </BaseCard>
      </v-col>
      <v-col cols="12" sm="8">
        <BaseCard heading="Historial de Retiros">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <v-alert
            text
            dense
            color="info"
            icon="mdi-information"
            border="left"
            class="subtitle-2"
            ><b>!Atención!</b> Los retiros de fondos pueden tardar de 2 a 24 hrs.</v-alert>
            <div class="mt-0">
                <v-data-table
                    dense
                    fixed-header
                    height="350px"
                    :headers="headers"
                    :items="histRetiros"
                    item-key="id"
                    sort-by="fecha"
                    :sort-desc="true"
                    :items-per-page="10"
                    :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                    }"
                    :custom-sort="customSort"
                    :loading="loading"
                    loading-text="Cargando... Un momento por favor"
                >
                <template v-slot:item.status="{ item }">
                    <v-chip
                        :color="getColor(item.status)"
                        dark
                        class="v-chip-status"
                    >
                        {{ item.status }}
                    </v-chip>
                </template>
                </v-data-table>
            </div>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VsToast from '@vuesimple/vs-toast'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Retiros",

    data: () => ({
        usrToken: '',
        usrData: {},
        monto_inicial: 0,
        va_monto: null,
        msgError: '',
        viewError: false,
        viewSuccess: false,
        loading: true,
        retSaldo: 0,
        respRetiro: {},
        headers: [
            { text: 'Fecha', value: 'fecha', width: 135 },
            { text: 'Valor', value: 'valor' },
            { text: 'Formato', value: 'formato' },
            { text: 'Wallet', value: 'wallet', width: 220},
            { text: 'Descripción', value: 'descripcion' },
            { text: 'Status', value: 'status' },
        ],
        histRetiros: [],
        loadingB: false, 
        nuevo: true,
        dialog: true
    }),
    components: {
        sesionExpiredDialog,
    },
    methods: {
         getColor (status) {
            if (status == 'Pendiente') return 'orange'
            else if (status == 'Rechazada') return 'red'
            else return 'green'
        },
        getRetiros() {            
            //Obtener los datos del Transacciones de Inversión
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/recompensas_d?tp_transaccion=Retiro`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTransaccion = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTransaccion.success){
                    console.error('Transacciones Error: ', respTransaccion)
                }
                else {
                    this.histRetiros = respTransaccion.data.map((trans) => {
                        return {
                            id: trans.id,
                            tipo: trans.tp_transaccion,
                            valor: trans.va_monto,
                            formato: trans.walletusers ? trans.walletusers.tipo_wallet.nb_wallet : 'N/A',
                            wallet: trans.walletusers ? trans.walletusers.wallet : 'N/A',
                            descripcion: trans.tp_transaccion,
                            fecha: moment(trans.fe_recompensa).format('YYYY-MM-DD HH:mm'),
                            status: trans.tx_status
                        }
                    })
                    //.Transacciones.toLocaleString("es-VE")
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Transacciones Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
            })
        },
        genRetiro() {
            if(!this.checkMonto()) return
            
            this.loadingB = true
            //Obtener los datos del Calculadora de Inversión
            axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/users/claim`,
                params: {
                    tipo_recompensas_id: 1,
                    va_monto: this.va_monto,
                    wallet_users_id: this.usrData.wallet.id_wallet
                },
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respSolRetiro = response.data
                console.log('Retiro...', respSolRetiro);
                if (!respSolRetiro.success){
                    VsToast.show({
                        title: 'Retiro de fondos',
                        message: 'Solicitud de retiro de fondos no puede ser procesada.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.msgError = respSolRetiro.data.msg
                    this.viewError = true
                    this.$refs.vaMonto.focus()
                }
                else {
                    console.log('Retiro Succes: ', respSolRetiro)
                    this.viewError= false
                    //if(respSolRetiro.data.walletusers && respSolRetiro.data.walletusers.tipo_wallet){
                        this.va_monto= null
                        this.viewSuccess= true
                        /* this.respRetiro= {
                            nb_wallet: respSolRetiro.data.walletusers.tipo_wallet.nb_wallet,
                            tp_wallet: respSolRetiro.data.walletusers.tipo_wallet.nb_wallet.split(' ')[0],
                            nro_wallet: respSolRetiro.data.walletusers.wallet
                        } */
                        this.getRetiros()
                    //}
                    VsToast.show({
                        title: 'Retiro de fondos',
                        message: 'Retiro de fondos cargada exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.nuevo = false
                }
                this.loadingB = false
            })
            .catch(error => {
                VsToast.show({
                    title: 'Retiro de fondos',
                    message: 'Retiro de fondos no puede ser procesada.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('Retiro Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
                this.viewError = true
                this.loadingB= false
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
                else
                    this.$refs.vaMonto.focus()
            })

        },
        getSaldoRetiro() {
            //Obtener los datos del Balance de Inversión
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance_d`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respPlan = response.data
                //console.log('balance...', respPlan);
                if (!respPlan.success){
                    console.error('Balance Error: ', respPlan)
                }
                else {
                    this.retSaldo = respPlan.data.balance_d.toLocaleString("es-VE")
                }
            })
            .catch(error => {
                console.error('Balance Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
            })
        },
        
        checkMonto() {
            if(this.va_monto < this.monto_inicial){
                this.msgError = `El monto ingresado es inferior al minimo de retiro.`
                this.viewError = true
                this.$refs.vaMonto.focus()
                console.error(this.msgError)

                return false
            }
            
            this.viewError = false
            return true

        },
        
        customSort: function(items, index, isDesc) {
            console.log('index: ', index, isDesc)
            items.sort((a, b) => {
                ///if (index[0]=='date' || index[0]=='fecha') {
                if(Date.parse(a[index]) || Date.parse(b[index]) ){
                    //console.log('a: ', a[index], new Date(a[index]))
                    //console.log('b: ', b[index], new Date(b[index]))
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },
    
        nuevoRetiro () {
            this.nuevo = true
            this.viewSuccess = false
        },
  },

  async created () {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        let appConfig = await JSON.parse(localStorage.getItem("vars"))
        this.usrToken = localStorage.getItem("usrToken")
        //this.monto_inicial = Number(process.env.VUE_APP_MONTO_MIN_RETIRO)
        this.monto_inicial = Number(appConfig.va_min_retiro)
        //Obtener el saldo disponible
        this.getSaldoRetiro()
        this.getRetiros()
  },
};
</script>

<style>
/* .warning_text input, .warning_text fieldset {
    caret-color: #fb8c00 !important;
    border-color: #fb8c00 !important;
    border-width: 2px;
    font-size: 20px;
}
.warning_text.v-input--is-focused fieldset, .warning_text.v-input--has-state fieldset {
    caret-color: #fb8c00 !important;
    border-color: #fb8c00 !important;
}
 .warning_text .v-icon {
    color: #fb8c00 !important;
 } */
 
.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning:hover, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-warning:hover{
    background-color: #fff8ec;
    border-color: #fb8c00;
    color: #fb8c00!important
}

.recuadro_success{
    background-color: #e6f8fa;
    color: #21c1d6!important

}

.saldo{
    background-color: #e8fdeb;
    border-color: #e8fdeb;
    color:rgba(6,215,156, 1)!important

}
.v-chip-status {
    border-radius: 20px !important;
    font-size: 12px !important;
    height: 24px !important;
}
</style>